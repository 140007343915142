import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { GetReportSettings, GetSalesCompanyTaskDetails } from '../../../Services';
import { useParams } from 'react-router-dom';
import GridMapV5 from '../../../components/gridReport/v5/gridMap/GridMapV5';
import './ReportPublicView.css';
import moment from 'moment';

const ReportPublicView = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ gridData, setGridData ] = useState();
	const [ task, setTask ] = useState();
	const [ reportSettings, setReportSettings ] = useState();
	const { reportId } = useParams();

	useEffect(
		() => {
			if (reportId) {
				getReportSettings();
				getSalesCompanyTask();
			}
		},
		[ reportId ]
	);

	const getSalesCompanyTask = async () => {
		const response = await GetSalesCompanyTaskDetails(reportId);

		if (response) {
			setTask(response);

			//parse grid data
			if (response.content) {
				let gridReportData = JSON.parse(response.content);

				if (gridReportData) {
					setGridData({
						googlePlaceId    : gridReportData.googlePlaceId,
						lat              : response.lat,
						lng              : response.lng,
						horizontalPoints : response.gridPoints,
						verticalPoints   : response.gridPoints,
						...(gridReportData.gridResponse && gridReportData.gridResponse.data
							? gridReportData.gridResponse.data[0]
							: null)
					});
				}
			}
		}

		setIsLoading(false);
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	return (
		<div className="container public-view-report text-center mt-3">
			{isLoading ? (
				<Spinner />
			) : (
				<Fragment>
					{task && (
						<Fragment>
							<div className="company-name text-start">{task.salesCompanyName}</div>
							<div className="keyword text-start">{`Keyword: ${task.keyword}`}</div>
							<div className="created-date text-start">{`Date: ${moment(task.dateCreated).format(
								'MM/DD/YYYY [@] hh:mm A [EST]'
							)}`}</div>

							<div className="public-grid-map-container">
								<Fragment>
									{gridData && (
										<GridMapV5
											key="map-0"
											mapIdValue="report-map-0"
											gridData={gridData}
											placeId={gridData.googlePlaceId}
											isFullHeight={true}
											reportSettings={reportSettings}
										/>
									)}
								</Fragment>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default ReportPublicView;
