import { useState, useEffect, Fragment } from 'react';
import { useStateValue } from '../../StateProvider';
import './NewReportApiTest.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainAppContentArea from '../../components/mainAppContentArea/MainAppContentArea';
import PageHeadline from '../../components/pageHeadline/PageHeadline';
import { GetBusinessInfoTaskPost } from '../../ReportAPI';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
// import 'codemirror/theme/material.css';

const NewReportApiTest = () => {
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ { user, host }, dispatch ] = useStateValue();
	const [ methodTitle, setMethodTitle ] = useState('');
	const [ placeId, setPlaceId ] = useState('');
	const [ apiResponse, setApiResponse ] = useState('');
	const [ selectedApiMethod, setSelectedApiMethod ] = useState();
	const dataForSeoApiMethods = [
		{ value: 'GetBusinessProfileInfoStandard', label: 'Get Business Profile Info (Standard)' },
		{ value: 'GetBusinessProfileInfoLive', label: 'Get Business Profile Info (Live)' }
	];
	const serpApiMethods = [ { value: 'GetBusinessProfileReviews', label: 'Get Business Profile Reviews' } ];

	const handleSubmit = () => {
		if (selectedApiMethod) {
			switch (selectedApiMethod.value) {
				case 'GetBusinessProfileInfoStandard':
					handleGetBusinessProfileInfo();
					break;
			}
		}
	};

	const handleGetBusinessProfileInfo = async () => {
		if (!placeId || placeId.trim() === '') {
			toast.error('Place Id is required field');
			return;
		}

		setIsSubmitting(true);

		let data = { placeId: placeId, lat: 27.2625347, lng: -82.5276239, zoom: 12 };

		let response = await GetBusinessInfoTaskPost(data);

		if (!response || !response.success || !response.data) {
			setApiResponse('ERROR: ' + response.message || 'ERROR: Could not get API response');
		} else {
			setApiResponse(JSON.stringify(response.data, null, 2));
			//setApiResponse(JSON.stringify(response.data));
		}

		setIsSubmitting(false);
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				<div className="m-3">
					<h4 className="mb-3 py-2 border-bottom">API Methods</h4>
					<div className="accordion" id="accordionDataForSeo">
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemDataForSeo"
									aria-expanded="true"
									aria-controls="accordionItemDataForSeo"
								>
									DataForSEO API
								</button>
							</h2>
							<div
								id="accordionItemDataForSeo"
								className="accordion-collapse collapse show"
								data-bs-parent="#accordionDataForSeo"
							>
								<div className="accordion-body">
									{dataForSeoApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn btn-link p-0 btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'active'
												: ''}`}
											onClick={() => setSelectedApiMethod(apiMethod)}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header">
								<button
									className="accordion-button"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#accordionItemSerp"
									aria-expanded="false"
									aria-controls="accordionItemSerp"
								>
									Serp API
								</button>
							</h2>
							<div
								id="accordionItemSerp"
								className="accordion-collapse collapse"
								data-bs-parent="#accordionSerp"
							>
								<div className="accordion-body">
									{serpApiMethods.map((apiMethod, index) => (
										<button
											key={index}
											className={`btn btn-link p-0 btn-api-method ${selectedApiMethod &&
											apiMethod.value === selectedApiMethod.value
												? 'active'
												: ''}`}
											onClick={() => setSelectedApiMethod(apiMethod)}
										>
											{apiMethod.label}
										</button>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<MainAppContentArea>
				<div className="row">
					<div className="col-12">
						<PageHeadline headline={methodTitle} />
					</div>
				</div>
				<div className="main-content-container">
					<div className="main-content-inner-container">
						{!selectedApiMethod && (
							<div className="row p-3">
								<div className="col-12">Select API method</div>
							</div>
						)}

						{selectedApiMethod && (
							<form className="row p-3">
								<h5 className="mb-3">{selectedApiMethod.label}</h5>
								<label htmlFor="placeId" className="form-label col-12">
									Place Id:
								</label>
								<div className="col-12 mb-3">
									<input
										type="text"
										className="form-control"
										name="placeId"
										value={placeId}
										onChange={(e) => setPlaceId(e.target.value)}
										disabled={isSubmitting}
									/>
								</div>
								<div className="col-12 mb-3">
									<button className="btn btn-primary" type="button" onClick={handleSubmit}>
										{isSubmitting ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Submitting...</span>
											</Fragment>
										) : (
											<span>Submit</span>
										)}
									</button>
								</div>
								<div className="col-12 mb-3">
									<hr className="col-12" />
								</div>
								<label htmlFor="apiResponse" className="form-label col-12">
									Response:
								</label>
								<div className="col-12 mb-3">
									<pre className="form-control json-viewer">
										<code>{apiResponse}</code>
									</pre>

									{/* <CodeMirror
										value={apiResponse}
										className="form-control"
										options={{
											mode         : { name: 'javascript', json: true },
											//theme       : 'material',
											lineNumbers  : true,
											lineWrapping : true
										}}
										onChange={(editor, data, value) => {}}
									/> */}

									{/* <textarea
										className="form-control"
										name="apiResponse"
										value={apiResponse}
										onChange={(e) => setApiResponse(e.target.value)}
										rows={20}
										disabled={isSubmitting}
									/> */}
								</div>
							</form>
						)}
					</div>
				</div>
			</MainAppContentArea>
		</div>
	);
};

export default NewReportApiTest;
