import axios from 'axios';
import { getToken } from './utils/StorageUtil';

let apiHost = 'https://gbpapi.gbpboost.com';
// let apiHost = 'https://dev-api.gbpboost.com';
const apiKey = 'AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg';
const apiHeader = 'X-API-KEY';
const apiHeaderValue = 'IQO?EXZiro?-|m%X15;vx1Q>L}bxb,<?M7=(J56Ss[K7o+;^6a7}J!dW6i8LJk2';

if (window.location.origin) {
	if (
		window.location.origin.indexOf('localhost') > -1 ||
		window.location.origin.indexOf('dev.') > -1 ||
		window.location.origin.indexOf('dev-') > -1
	) {
		apiHost = 'https://localhost:7172';
		//apiHost = 'https://dev-api.gbpboost.com';
	}
}

// setup axios to inject the authorization header automatically
// if token is present in store
axios.interceptors.request.use((config) => {
	let token = getToken();

	if (token && token.trim() !== '') {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

// export const GetNewCampaignsForPortal = async () => {
// 	const response = await axios
// 		.get(apiHost + '/campaigns/list/new/portal', null, {
// 			headers : {
// 				'Content-Type' : 'application/json'
// 			}
// 		})
// 		.then((response) => {
// 			return response;
// 		})
// 		.catch((err) => {
// 			return { data: { IsSuccessful: false, Message: err } };
// 		});

// 	return response.data;
// };

export const GetBusinessInfoTaskPost = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/business/info/tasks/post', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};
