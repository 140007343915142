import { Fragment, useEffect, useRef, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './GridReportsList.css';
import { DeleteSalesCompany, DeleteSalesCompanyTask, GetUserReportsListForSalesPortal } from '../../../Services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';
import { useStateValue } from '../../../StateProvider';
import TableFooter from '../../../components/table/footer/TableFooter';

const GridReportsList = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ allReports, setAllReports ] = useState();
	const [ reportsView, setReportsView ] = useState();
	const [ { user }, dispatch ] = useStateValue();
	const searchListInputRef = useRef();

	useEffect(() => {
		getReportsList(true);
	}, []);

	const filterList = () => {
		if (allReports && allReports.length > 0) {
			setReportHistoryView(allReports);
		}
	};

	const onSearchChange = (e) => {
		if (searchListInputRef.current) {
			if (searchListInputRef.current.value === '') {
				searchListInputRef.current.classList.remove('has-value');
			} else {
				searchListInputRef.current.classList.add('has-value');
			}
		}

		filterList();
	};

	const getReportsList = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetUserReportsListForSalesPortal(user.id);

		if (response && response.length > 0) {
			let tempAllReports = response;

			setAllReports(tempAllReports);

			setReportHistoryView(tempAllReports);
		} else {
			setReportsView();
		}

		setIsLoading(false);
	};

	const setReportHistoryView = (reports) => {
		let tempReportsView = reports;

		if (searchListInputRef.current && searchListInputRef.current.value) {
			tempReportsView = reports.filter((x) =>
				x.name.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
			);
		}

		tempReportsView = tempReportsView.sort(function(a, b) {
			return new Date(b.lastReportDate) - new Date(a.lastReportDate);
		});

		setReportsView(tempReportsView);
	};

	const handleDeleteCompany = async (e, id) => {
		e.preventDefault();

		if (window.confirm('Are you sure that you want to delete selected business?')) {
			let response = await DeleteSalesCompany(id);

			if (response) {
				toast.success('Business successfully deleted');

				getReportsList();
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid p-0">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							//headline="Reports"
							leftSideHtml={
								<Link type="button" className="btn btn-primary" to="/profile-connect">
									New Grid Report
								</Link>
							}
							buttonsHtml={
								<Fragment>
									{user && (
										<div className="d-flex justify-content-end">
											<div className="search-list-wrapper align-self-start">
												<input
													type="search"
													className="form-control"
													name="searchListTerm"
													onChange={onSearchChange}
													placeholder="Search List"
													ref={searchListInputRef}
													tabIndex="0"
												/>
												<button type="button" className="btn btn-link btn-search px-2">
													<i className="fa fa-search me-0" />
												</button>
											</div>
										</div>
									)}
								</Fragment>
							}
						/>
					</div>
				</div>

				<div className="main-content-container reports-list-container">
					<div className="main-content-inner-container">
						<table className="items-list grid-reports-list table table-responsive">
							<thead>
								<tr>
									<th scope="col">Business</th>
									<th className="date text-start d-none d-sm-table-cell" scope="col">
										Last Report
									</th>
									<th className="count d-none d-sm-table-cell" scope="col">
										Keywords
									</th>
									<th scope="col" />
								</tr>
							</thead>
							{isLoading ? (
								<tbody>
									<tr>
										<td className="p-3" colSpan={11}>
											<Spinner />
										</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{reportsView && reportsView.length > 0 ? (
										reportsView.map((report, index) => (
											<tr key={index}>
												<td>{report.name}</td>
												<td className="date text-start d-none d-sm-table-cell">
													{report.lastReportDate ? (
														<Moment format="MM/DD/YYYY">{report.lastReportDate}</Moment>
													) : (
														''
													)}
												</td>
												<td className="count d-none d-sm-table-cell">{report.keywordsCount}</td>
												<td className="task-action text-end">
													<Link
														to={`/business/${report.id}/reports/details`}
														className="btn btn-link py-0"
														title="View Report"
													>
														<i className="fa-solid fa-magnifying-glass-chart" />
													</Link>
													<Link
														to={`/business/${report.id}/reports/history`}
														className="btn btn-link py-0"
														title="View Reports History"
													>
														<i className="fa-solid fa-list-check" />
													</Link>
													<button
														type="button"
														className="btn btn-link py-0 text-danger"
														title="Delete Business"
														onClick={(e) => handleDeleteCompany(e, report.id)}
													>
														{/* Delete */}
														<i className="fa-solid fa-trash-can" />
													</button>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td className="p-3" colSpan={5}>
												<i>No reports found</i>
											</td>
										</tr>
									)}
								</tbody>
							)}
							{!isLoading && (
								<TableFooter
									list={reportsView}
									colspan={6}
									showRefreshButton={true}
									onListRefresh={() => getReportsList(true)}
								/>
							)}
						</table>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default GridReportsList;
