import { Fragment, useEffect, useRef, useState } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignsList.css';
import { GetActiveCampaigns, GetCanceledCampaigns, GetNewCampaignsForPortal } from '../../../Services';
import { Link } from 'react-router-dom';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import Moment from 'react-moment';
import TableFooter from '../../../components/table/footer/TableFooter';
import RequirementsDialog from '../requirementsDialog/RequirementsDialog';
import NewCampaignDialog from '../newCampaignDialog/NewCampaignDialog';
import { useStateValue } from '../../../StateProvider';

const CampaignsList = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ allCampaigns, setAllCampaigns ] = useState([]);
	const [ activeCampaigns, setActiveCampaigns ] = useState([]);
	const [ activeCampaignsListView, setActiveCampaignsListView ] = useState();
	const [ newCampaigns, setNewCampaigns ] = useState([]);
	const [ newCampaignsListView, setNewCampaignsListView ] = useState([]);
	const [ canceledCampaigns, setCanceledCampaigns ] = useState([]);
	const [ canceledCampaignsListView, setCanceledCampaignsListView ] = useState([]);
	const searchListInputRef = useRef();
	const [ showRequirementsDetailsDialog, setShowRequirementsDetailsDialog ] = useState(false);
	const [ requirementsDetailsData, setRequirementsDetailsData ] = useState({});
	const [ editedRequirements, setEditedRequirements ] = useState({});
	const [ isLoadingDialogDetails, setIsLoadingDialogDetails ] = useState(false);
	const [ showNewCampaignDialog, setShowNewCampaignDialog ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();

	useEffect(() => {
		document.title = 'Campaigns List';

		getActiveCampaigns(true);
		getNewCampaigns(true);
		getCanceledCampaigns(true);

		// let processesInterval = setInterval(() => {
		// 	//getActiveCampaigns();
		// }, 10000);

		// return () => {
		// 	clearInterval(processesInterval);
		// };
	}, []);

	useEffect(() => {
		//window.addEventListener('focus', onFocus);
		document.addEventListener('visibilitychange', handleTabFocus);

		// Specify how to clean up after this effect:
		return () => {
			//window.removeEventListener('focus', onFocus);
			document.removeEventListener('visibilitychange', handleTabFocus);
		};
	}, []);

	// User has switched back to the tab
	const handleTabFocus = () => {
		if (document.visibilityState === 'visible') {
			//console.log('Tab is in focus');
			getNewCampaigns();
		}
	};

	const filterList = () => {
		if (activeCampaigns && activeCampaigns.length > 0) {
			let tempActiveCampaigns = applyCampaignsSearchFilter(activeCampaigns, true);
			setActiveCampaignsListView(tempActiveCampaigns);
		} else {
			setActiveCampaignsListView([]);
		}
	};

	const onSearchChange = (e) => {
		if (searchListInputRef.current) {
			if (searchListInputRef.current.value === '') {
				searchListInputRef.current.classList.remove('has-value');
			} else {
				searchListInputRef.current.classList.add('has-value');
			}
		}

		filterList();
	};

	const applyCampaignsSearchFilter = (campaignsList) => {
		if (campaignsList) {
			if (searchListInputRef.current && searchListInputRef.current.value) {
				campaignsList = campaignsList.filter((x) => {
					let nameCond = x.name.toLowerCase().includes(searchListInputRef.current.value.toLowerCase());
					let emailCond =
						x.email && x.email !== ''
							? x.email.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false;
					let userEmailCond =
						x.userEmail && x.userEmail !== ''
							? x.userEmail.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())
							: false;

					return nameCond || emailCond || userEmailCond;
				});
			}
		}

		return campaignsList;
	};

	const getActiveCampaigns = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetActiveCampaigns();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			let tempActiveCampaigns = response;

			tempActiveCampaigns = tempActiveCampaigns.sort((a, b) => a.name.localeCompare(b.name));
			tempActiveCampaigns = applyCampaignsSearchFilter(tempActiveCampaigns);

			setActiveCampaigns(tempActiveCampaigns);
			setActiveCampaignsListView(tempActiveCampaigns);
			setAllCampaigns(tempActiveCampaigns);
		} else {
			setActiveCampaignsListView([]);
		}

		setIsLoading(false);
	};

	const getCanceledCampaigns = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetCanceledCampaigns();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			response = response.sort((a, b) => a.name.localeCompare(b.name));
			response = applyCampaignsSearchFilter(response);

			setCanceledCampaigns(response);
			setCanceledCampaignsListView(response);
		}

		setIsLoading(false);
	};

	const getNewCampaigns = async (fullReload = false) => {
		if (fullReload) {
			setIsLoading(true);
		}

		let response = await GetNewCampaignsForPortal();

		if (response.IsSuccessful === false) {
			if (
				response.Message &&
				response.Message.response &&
				response.Message.response.status &&
				response.Message.response.status === 401
			) {
				//navigate('/login');
			}
		}

		if (response) {
			let tempNewCampaigns = response;

			tempNewCampaigns.forEach((campaign) => {
				if (campaign.status === 'Active') {
					campaign.isRequirement = true;
					campaign.status = 'Requirements';
				} else if (campaign.status === 'Approval') {
					campaign.isRequirement = true;
				} else if (campaign.status === 'New') {
					campaign.isRequirement = false;
					campaign.status = 'In Progress';
				} else if (campaign.status === 'Approved') {
					campaign.isRequirement = false;
				} else {
					campaign.isRequirement = false;
				}
			});

			tempNewCampaigns = tempNewCampaigns.sort((a, b) => a.name.localeCompare(b.name));
			tempNewCampaigns = applyCampaignsSearchFilter(tempNewCampaigns);

			setNewCampaigns(tempNewCampaigns);
			setNewCampaignsListView(tempNewCampaigns);
		}

		setIsLoading(false);
	};

	const toggleRequirementsDetailsDialog = async (requirements) => {
		setShowRequirementsDetailsDialog(!showRequirementsDetailsDialog);

		if (!showRequirementsDetailsDialog) {
			setIsLoadingDialogDetails(true);

			if (requirements && requirements.id) {
				setEditedRequirements(requirements);
			} else {
				setIsLoadingDialogDetails(false);
			}
		} else {
			setRequirementsDetailsData({});
		}
	};

	const toggleNewCampaignDialog = async (e) => {
		setShowNewCampaignDialog(!showNewCampaignDialog);

		if (e) e.preventDefault();
	};

	const handleSelectDefaultOffer = (id) => {
		setShowNewCampaignDialog(!showNewCampaignDialog);
	};

	return (
		<Fragment>
			<div className="row">
				<div className="col-12">
					<PageHeadline
						headline=""
						showSearchBox={true}
						leftSideHtml={
							<Fragment>
								{user.isSalesUser ? (
									<Fragment />
								) : (
									<button type="button" className="btn btn-primary" onClick={toggleNewCampaignDialog}>
										<span>New Campaign</span>
									</button>
								)}
							</Fragment>
						}
						buttonsHtml={
							<Fragment>
								<div className="d-flex justify-content-end">
									<div className="search-list-wrapper align-self-start">
										<input
											type="search"
											className="form-control"
											name="searchListTerm"
											onChange={onSearchChange}
											placeholder="Search List"
											ref={searchListInputRef}
											tabIndex="0"
										/>
										<button type="button" className="btn btn-link btn-search px-2">
											<i className="fa fa-search me-0" />
										</button>
									</div>
								</div>
							</Fragment>
						}
					/>
				</div>
			</div>

			<Fragment>
				<div className="main-content-container campaigns-list-container">
					<ul className="nav nav-tabs" id="tabCampaigns" role="tablist">
						{newCampaignsListView &&
						newCampaignsListView.length > 0 &&
						!user.isSalesUser && (
							<li className="nav-item" role="presentation" key="new-campaigns">
								<button
									className={`nav-link ${user.isSalesUser ? '' : 'active'}`}
									id="new-campaigns-tab"
									data-bs-toggle="tab"
									data-bs-target="#new-campaigns-tab-pane"
									type="button"
									role="tab"
									aria-controls="new-campaigns-tab-pane"
									aria-selected="true"
								>
									New
								</button>
							</li>
						)}
						<li className="nav-item" role="presentation" key="active-campaigns">
							<button
								className={`nav-link ${!newCampaignsListView ||
								newCampaignsListView.length === 0 ||
								user.isSalesUser
									? 'active'
									: ''}`}
								id="active-campaigns-tab"
								data-bs-toggle="tab"
								data-bs-target="#active-campaigns-tab-pane"
								type="button"
								role="tab"
								aria-controls="active-campaigns-tab-pane"
								aria-selected="true"
							>
								Active
							</button>
						</li>
						{canceledCampaignsListView &&
						canceledCampaignsListView.length > 0 &&
						!user.isSalesUser && (
							<li className="nav-item" role="presentation" key="canceled-campaigns">
								<button
									className="nav-link"
									id="canceled-campaigns-tab"
									data-bs-toggle="tab"
									data-bs-target="#canceled-campaigns-tab-pane"
									type="button"
									role="tab"
									aria-controls="canceled-campaigns-tab-pane"
									aria-selected="false"
								>
									Canceled
								</button>
							</li>
						)}
					</ul>
					<div className="main-content-inner-container">
						<div className="tab-content border" id="tabCampaignsContent">
							{newCampaignsListView &&
							newCampaignsListView.length > 0 &&
							!user.isSalesUser && (
								/* ----- new campaigns ----- */
								<div
									className={`tab-pane fade ${user.isSalesUser ? '' : 'show active'}`}
									id="new-campaigns-tab-pane"
									role="tabpanel"
									aria-labelledby="new-campaigns-tab"
									tabIndex="0"
								>
									<table className="items-list campaigns-list table table-responsive">
										<thead>
											<tr>
												<th className="campaign-name" scope="col">
													Name
												</th>
												<th className="status d-none d-sm-table-cell text-start" scope="col">
													Status
												</th>
												<th className="task-action" scope="col" />
											</tr>
										</thead>
										{isLoading ? (
											<tbody>
												<tr>
													<td className="p-3" colSpan={11}>
														<Spinner />
													</td>
												</tr>
											</tbody>
										) : (
											<tbody>
												{newCampaignsListView && newCampaignsListView.length > 0 ? (
													newCampaignsListView.map((item, index) => {
														return (
															<Fragment key={index}>
																<tr>
																	<td className="campaign-name text-truncate">
																		{`${item.name}${item.nameExtension
																			? ` - ${item.nameExtension}`
																			: ''}`}
																	</td>
																	<td className="count d-none d-sm-table-cell text-start">
																		{item.status}
																	</td>
																	<td className="task-action text-end">
																		{item.status === 'Requirements' ? (
																			<button
																				className="btn btn-link py-0"
																				//to={`/campaigns/requirements/${item.id}`}
																				onClick={() =>
																					toggleRequirementsDetailsDialog(
																						item
																					)}
																			>
																				Change
																			</button>
																		) : item.status === 'Approval' ? (
																			<a
																				className="btn btn-link py-0"
																				href={`/map-boost-approval/${item.id}`}
																				target="_blank"
																			>
																				Approve
																			</a>
																		) : item.status === 'Order' ? (
																			<a
																				className="btn btn-link py-0"
																				href={`/orders/${item.id}/requirements`}
																				target="_blank"
																			>
																				Process
																			</a>
																		) : (
																			''
																		)}
																	</td>
																</tr>
															</Fragment>
														);
													})
												) : (
													<tr>
														<td className="p-3" colSpan={11}>
															<i>No new campaigns</i>
														</td>
													</tr>
												)}
											</tbody>
										)}

										{!isLoading && (
											<TableFooter
												list={newCampaignsListView}
												colspan={10}
												showRefreshButton={true}
												onListRefresh={() => getNewCampaigns(true)}
											/>
										)}
									</table>
								</div>
							)}

							{/* ----- active campaigns ----- */}
							<div
								className={`tab-pane fade ${!newCampaignsListView ||
								newCampaignsListView.length === 0 ||
								user.isSalesUser
									? 'show active'
									: ''}`}
								id="active-campaigns-tab-pane"
								role="tabpanel"
								aria-labelledby="active-campaigns-tab"
								tabIndex="0"
							>
								<table className="items-list campaigns-list table table-responsive">
									<thead>
										<tr>
											<th className="campaign-name" scope="col">
												Name
											</th>
											<th className="date d-none d-sm-table-cell" scope="col">
												Last Report
											</th>
											<th className="date d-none d-sm-table-cell" scope="col">
												Next Report
											</th>
											<th className="date d-none d-sm-table-cell text-center" scope="col">
												Total Reports
											</th>
											<th className="task-action" scope="col" />
										</tr>
									</thead>
									{isLoading || !activeCampaignsListView ? (
										<tbody>
											<tr>
												<td className="p-3" colSpan={5}>
													<Spinner />
												</td>
											</tr>
										</tbody>
									) : (
										<tbody>
											{activeCampaignsListView && activeCampaignsListView.length > 0 ? (
												activeCampaignsListView.map((campaign, index) => {
													return (
														<Fragment key={index}>
															<tr>
																<td
																	className="campaign-name text-truncate"
																	title={`${campaign.name}${campaign.nameExtension
																		? ` - ${campaign.nameExtension}`
																		: ''}`}
																>
																	{`${campaign.name}${campaign.nameExtension
																		? ` - ${campaign.nameExtension}`
																		: ''}`}
																</td>
																<td className="date d-none d-sm-table-cell">
																	{campaign.lastReportDate ? (
																		<Moment format="MM/DD/YYYY">
																			{campaign.lastReportDate}
																		</Moment>
																	) : (
																		''
																	)}
																</td>
																<td className="date d-none d-sm-table-cell">
																	{campaign.nextReportDate ? (
																		<Moment format="MM/DD/YYYY">
																			{campaign.nextReportDate}
																		</Moment>
																	) : (
																		''
																	)}
																</td>
																<td className="date d-none d-sm-table-cell text-center">
																	{campaign.reportSentHistoryRecordsCount || ''}
																</td>
																<td className="task-action text-end">
																	<Link
																		className="btn btn-link py-0"
																		to={`/campaigns/${campaign.id}`}
																		title="View Campaign"
																	>
																		<i className="fa-solid fa-magnifying-glass" />
																	</Link>
																</td>
															</tr>
														</Fragment>
													);
												})
											) : (
												<tr>
													<td className="p-3" colSpan={5}>
														<i>No active campaigns</i>
													</td>
												</tr>
											)}
										</tbody>
									)}

									{!isLoading && (
										<TableFooter
											list={activeCampaignsListView}
											colspan={10}
											showRefreshButton={true}
											onListRefresh={() => getActiveCampaigns(true)}
										/>
									)}
								</table>
							</div>

							{canceledCampaignsListView &&
							canceledCampaignsListView.length > 0 &&
							!user.isSalesUser && (
								/* ----- canceled campaigns ----- */
								<div
									className="tab-pane fade"
									id="canceled-campaigns-tab-pane"
									role="tabpanel"
									aria-labelledby="canceled-campaigns-tab"
									tabIndex="0"
								>
									<table className="items-list campaigns-list table table-responsive">
										<thead>
											<tr>
												<th className="campaign-name" scope="col">
													Name
												</th>
												<th className="date d-none d-sm-table-cell" scope="col">
													Canceled
												</th>
												<th className="task-action" scope="col" />
											</tr>
										</thead>
										{isLoading ? (
											<tbody>
												<tr>
													<td className="p-3" colSpan={3}>
														<Spinner />
													</td>
												</tr>
											</tbody>
										) : (
											<tbody>
												{canceledCampaignsListView && canceledCampaignsListView.length > 0 ? (
													canceledCampaignsListView.map((campaign, index) => {
														return (
															<Fragment key={index}>
																<tr>
																	<td className="campaign-name text-truncate">
																		{`${campaign.name}${campaign.nameExtension
																			? ` - ${campaign.nameExtension}`
																			: ''}`}
																	</td>
																	<td className="date d-none d-sm-table-cell">
																		{campaign.dateCanceled ? (
																			<Moment format="MM/DD/YYYY">
																				{campaign.dateCanceled}
																			</Moment>
																		) : (
																			''
																		)}
																	</td>
																	<td className="task-action text-end">
																		{/* <Link
																			className="btn btn-link py-0"
																			to={`/campaigns/${campaign.id}`}
																			title="View Campaign"
																		>
																			View
																		</Link> */}
																	</td>
																</tr>
															</Fragment>
														);
													})
												) : (
													<tr>
														<td className="p-3" colSpan={3}>
															<i>No canceled campaigns</i>
														</td>
													</tr>
												)}
											</tbody>
										)}

										{!isLoading && (
											<TableFooter
												list={canceledCampaignsListView}
												colspan={3}
												showRefreshButton={true}
												onListRefresh={() => getCanceledCampaigns(true)}
											/>
										)}
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</Fragment>

			{showRequirementsDetailsDialog && (
				<RequirementsDialog
					id={editedRequirements.id}
					onSubmit={() => {
						toggleRequirementsDetailsDialog();
						getNewCampaigns(true);
					}}
					onClose={toggleRequirementsDetailsDialog}
				/>
			)}

			{showNewCampaignDialog && (
				<NewCampaignDialog
					//onSubmit={toggleRequirementsDetailsDialog}
					onClose={toggleNewCampaignDialog}
				/>
			)}
		</Fragment>
	);
};

export default CampaignsList;
