import { NavLink } from 'react-router-dom';
import './LeftNavigationLinks.css';
import { Fragment } from 'react';
import { useStateValue } from '../../../StateProvider';

const LeftNavigationLinks = ({ onLinkClick }) => {
	const className = 'app-left-nav-link';
	const activeClassName = 'app-left-nav-link active';
	const [ { user }, dispatch ] = useStateValue();

	const handleLinkClick = (type) => {
		if (onLinkClick) {
			onLinkClick(type);
		}
	};

	return (
		<div className="app-left-nav-links">
			{user.isSalesUser ? (
				<Fragment>
					<NavLink
						to="/campaigns"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={handleLinkClick}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-bullhorn" />
						</div>
						<span className="app-left-nav-link-text">Campaigns</span>
					</NavLink>
					<NavLink to="/reports" className={({ isActive }) => (isActive ? activeClassName : className)}>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-chart-bar" />
						</div>
						<span className="app-left-nav-link-text">Reports</span>
					</NavLink>
					<NavLink
						to="/offers"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={handleLinkClick}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-hand-holding-dollar" />
						</div>
						<span className="app-left-nav-link-text">Offers</span>
					</NavLink>
				</Fragment>
			) : (
				<Fragment>
					<NavLink
						to="/campaigns"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={handleLinkClick}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-bullhorn" />
						</div>
						<span className="app-left-nav-link-text">Campaigns</span>
					</NavLink>
					<NavLink
						to="/support"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={() => handleLinkClick('support')}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-circle-question" />
						</div>
						<span className="app-left-nav-link-text">Support</span>
					</NavLink>
					<NavLink
						to="/learning"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={handleLinkClick}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-brands fa-youtube" />
						</div>
						<span className="app-left-nav-link-text">Learning</span>
					</NavLink>
					<NavLink
						to="/payments"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={handleLinkClick}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-money-check-dollar" />
						</div>
						<span className="app-left-nav-link-text">Payments</span>
					</NavLink>
					<NavLink
						to="/cart"
						className={({ isActive }) => (isActive ? activeClassName : className)}
						onClick={handleLinkClick}
					>
						<div className="app-left-nav-link-icon">
							<i className="fa-solid fa-cart-shopping" />
						</div>
						<span className="app-left-nav-link-text">Cart</span>
					</NavLink>
				</Fragment>
			)}
		</div>
	);
};

export default LeftNavigationLinks;
