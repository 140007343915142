import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import './../businessProfileConnect/BusinessProfileConnect.css';
import { Fragment } from 'react';
import './GridReportTool.css';
import { GetSalesBusiness, RemoveSalesBusiness, StoreSalesBusiness } from '../../../utils/StorageUtil';
import GridMapV5 from '../../../components/gridReport/v5/gridMap/GridMapV5';
import { toast } from 'react-toastify';
import {
	CreateSalesCompany,
	CreateSalesCompanyTask,
	DeleteSalesCompanyTask,
	GenerateSEOReportDataGrid,
	GetReportSettings,
	GetSalesCompanyDetails,
	GetSalesCompanyTaskDetails,
	GetSalesCompanyTasks,
	UpdateSalesCompany,
	UpdateSalesCompanyTask,
	UpdateSalesCompanyTaskIndexes
} from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import { copyToClipboard, createGuid, slugify } from '../../../utils/Common';
import moment from 'moment';
import html2canvas from 'html2canvas';

const GridReportTool = () => {
	const [ { user, host }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isLoadingMap, setIsLoadingMap ] = useState(false);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const [ companyData, setCompanyData ] = useState();
	const [ companyName, setCompanyName ] = useState();
	const [ areaRadius, setAreaRadius ] = useState();
	const [ keywords, setKeywords ] = useState();
	const [ selectedTask, setSelectedTask ] = useState();
	const navigate = useNavigate();
	const [ showGridReport, setShowGridReport ] = useState(false);
	const [ reportTasks, setReportTasks ] = useState();
	const [ dateCreated, setDateCreated ] = useState();
	const [ gridData, setGridData ] = useState();
	const [ reportSettings, setReportSettings ] = useState();
	const { companyId, reportName, reportId, editCompanyId } = useParams();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);
	const [ changesMade, setChangesMade ] = useState(false);
	const gridMapContainerRef = useRef();

	useEffect(() => {
		getReportSettings();
	}, []);

	useEffect(() => {
		if (companyId) {
			getCompanyDetails(companyId);
			getCompanyReportTasks(companyId);
			setShowGridReport(true);
		}
	}, []);

	useEffect(
		() => {
			if (editCompanyId) {
				setIsLoadingMap(true);
				setShowGridReport(false);
				getCompanyDetails(editCompanyId);
				getCompanyReportTasks(editCompanyId, true);
			}
		},
		[ editCompanyId ]
	);

	useEffect(
		() => {
			if (reportId) {
				getReportTaskDetails(reportId, true);
				setShowGridReport(true);
			}
		},
		[ reportId ]
	);

	useEffect(() => {
		if (!companyId && !editCompanyId && !reportId) {
			setIsLoadingMap(true);

			let tempBusiness = GetSalesBusiness();

			if (tempBusiness) {
				setSelectedBusiness(tempBusiness);

				setAreaRadius(tempBusiness.isServiceArea ? 7 : 2.5);

				setGridData({
					googlePlaceId : tempBusiness.place_id,
					lat           : tempBusiness.lat,
					lng           : tempBusiness.lng
				});
			}

			setIsLoadingMap(false);
		}
	}, []);

	useEffect(
		() => {
			if (window.mapReady) {
				setIsLoadingMap(false);
			}
		},
		[ window.mapReady ]
	);

	useEffect(
		() => {
			if (selectedBusiness) {
				setGridData({
					googlePlaceId : selectedBusiness.place_id,
					lat           : selectedBusiness.lat,
					lng           : selectedBusiness.lng
				});
			}
		},
		[ areaRadius, selectedBusiness ]
	);

	// useEffect(
	// 	() => {
	// 		if (!isLoading && areaRadius) {
	// 			setChangesMade(true);
	// 		}
	// 	},
	// 	[ areaRadius ]
	// );

	const getCompanyDetails = async (id) => {
		let response = await GetSalesCompanyDetails(id);

		if (response) {
			setCompanyData(response);
			setCompanyName(response.name);

			if (editCompanyId) {
				setGridData({
					googlePlaceId : response.googlePlaceId,
					lat           : response.lat,
					lng           : response.lng
				});
			}
		}
	};

	const getCompanyReportTasks = async (id) => {
		setIsLoading(true);

		let response = await GetSalesCompanyTasks(id);

		if (response && response.length > 0) {
			let tempTasks = response;
			let tempTask;

			if (reportName) {
				tempTasks = tempTasks.filter((x) => x.reportName === reportName);
			}

			if (tempTasks.length > 0) {
				setReportTasks(tempTasks);

				//sort by order index
				tempTasks = tempTasks.sort(function(a, b) {
					return a.orderIndex - b.orderIndex;
				});

				tempTask = tempTasks[0];
				setSelectedTask(tempTask);

				if (editCompanyId) {
					setAreaRadius(tempTask.gridRadius);

					setKeywords(tempTasks.map((task) => task.keyword).join('\n'));

					setIsLoadingMap(false);
				} else {
					await getReportTaskDetails(tempTask.id);
				}
			}
		}

		setIsLoading(false);
	};

	const getReportTaskDetails = async (id, isSingleTask = false, isSelectedTask = false) => {
		if (!isSelectedTask) {
			setIsLoading(true);
		}

		let response = await GetSalesCompanyTaskDetails(id);

		if (response) {
			let tempTask = response;

			setDateCreated(tempTask.lastUpdated || tempTask.dateCreated);

			if (isSingleTask) {
				let tempTasks = [ tempTask ];
				setReportTasks(tempTasks);

				setCompanyName(tempTask.salesCompanyName);
			}

			if (tempTask.content) {
				let gridReportData = JSON.parse(tempTask.content);

				if (gridReportData) {
					setGridData({
						googlePlaceId    : gridReportData.googlePlaceId,
						lat              : tempTask.lat,
						lng              : tempTask.lng,
						horizontalPoints : tempTask.gridPoints,
						verticalPoints   : tempTask.gridPoints,
						...(gridReportData.gridResponse && gridReportData.gridResponse.data
							? gridReportData.gridResponse.data[0]
							: null)
					});

					setIsLoadingMap(false);
				}
			} else {
				setIsLoadingMap(false);
			}
		} else {
			setIsLoadingMap(false);
		}

		setIsLoading(false);
	};

	const getReportSettings = async () => {
		let response = await GetReportSettings();

		if (response && response.data) {
			setReportSettings(response.data);
		}
	};

	const handleChange = async (e) => {
		if (e.target.name === 'areaRadius') {
			setAreaRadius(e.target.value);
			setChangesMade(true);
		} else if (e.target.name === 'keywords') {
			setKeywords(e.target.value);
		}
	};

	const generateReport = async (e) => {
		e.preventDefault();

		if (!keywords || keywords.trim() === '') {
			toast.error('Enter at least one keyword to continue');
			return;
		}

		let keywordsArrayForReport = [];

		if (editCompanyId && changesMade) {
			//radius has changed, thus create report for all keyword
			keywordsArrayForReport = keywords.split('\n');
		} else if (editCompanyId && !changesMade) {
			//run reports only for new keywords or for existing also if it's passed more than 7 days
			if (keywords && reportTasks && reportTasks.length > 0) {
				//first check if we changed or delete any of existing keywords
				reportTasks.forEach(async (reportTask) => {
					let existingKeyword = keywords
						.split('\n')
						.find((x) => x.toLowerCase() === reportTask.keyword.trim().toLowerCase());

					if (!existingKeyword) {
						//delete this task because the keyword is not anymore in the list
						const deleteTaskResponse = await DeleteSalesCompanyTask(editCompanyId, reportTask.id);

						if (!deleteTaskResponse) {
							console.log('Failed while delete task: ' + reportTask.id);
						}
					}
				});

				//go through keywords and create or update tasks
				keywords.split('\n').forEach((keyword) => {
					let existingTask = reportTasks.find(
						(x) => x.keyword.toLowerCase() === keyword.trim().toLowerCase()
					);

					if (!existingTask) {
						//no existing task for this keyword, so add it to run report keywords list
						keywordsArrayForReport.push(keyword);
					} else {
						let reportDate = existingTask.lastUpdated || existingTask.dateCreated;

						if (moment(reportDate).add(7, 'days').isBefore(moment(), 'day')) {
							//task exists for this keyword, but it was created more than 7 days ago
							keywordsArrayForReport.push(keyword);
						}
					}
				});
			}
		} else {
			keywordsArrayForReport = keywords.split('\n');
		}

		if (keywordsArrayForReport && keywordsArrayForReport.length > 5) {
			toast.error('You can set max 5 keywords');
			return;
		}

		if (editCompanyId && keywordsArrayForReport.length === 0) {
			//no any keyword changed or all keywords ran in the last 7 days, just redirect to report page
			window.location.href = `${host}/business/${editCompanyId}/reports/details`;
		}

		setIsGenerating(true);
		setIsLoading(true);

		//calculate grid points based on radius
		let gridPoints = 7;

		if (areaRadius > 5) {
			gridPoints = 9;
		} else if (areaRadius > 10) {
			gridPoints = 11;
		}

		let pointDistance = parseFloat(areaRadius * 2 / (gridPoints - 1));
		let companyId;
		let tempCompany;

		if (editCompanyId) {
			const companyResponse = await GetSalesCompanyDetails(editCompanyId);

			if (companyResponse) {
				companyId = companyResponse.id;
				tempCompany = companyResponse;
			}
		}

		if (!tempCompany) {
			let email =
				selectedBusiness.name.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

			//first create company if it doesn't exist
			let companyData = {
				...selectedBusiness,
				userId            : selectedBusiness.salesUserId ? selectedBusiness.salesUserId : user.id,
				email             : email,
				phone             : selectedBusiness.formatted_phone_number,
				googleCid         : selectedBusiness.cid,
				googlePlaceId     : selectedBusiness.place_id,
				googleBusinessUrl : selectedBusiness.googleBusinessUrl
					? selectedBusiness.googleBusinessUrl
					: selectedBusiness.cid ? 'https://maps.google.com/?cid=' + selectedBusiness.cid : '',
				status            : 'Active'
			};

			const companyResponse = await CreateSalesCompany(companyData);

			if (!companyResponse.success) {
				if (
					companyResponse.data &&
					companyResponse.message.indexOf('already exists') > -1 &&
					companyResponse.data.userId === user.id
				) {
					companyId = companyResponse.data.id;
					tempCompany = companyResponse.data;

					//update lat and lng if necessary
					if (selectedBusiness && selectedBusiness.lat !== tempCompany.lat) {
						tempCompany.lat = selectedBusiness.lat;
						tempCompany.lng = selectedBusiness.lng;

						const updateCompanyResponse = await UpdateSalesCompany(companyId, tempCompany);

						if (!updateCompanyResponse.success) {
							console.log('Company lat/lng not updated. Reason: ' + updateCompanyResponse.message);
						}
					}
				}
			} else {
				tempCompany = companyResponse.data;
				companyId = companyResponse.data.id;
			}
		}

		if (tempCompany) {
			RemoveSalesBusiness();

			//create report
			let reportData = {
				salesUserId   : user && user.isSalesUser ? user.id : null,
				campaignId    : null,
				keywords      : keywordsArrayForReport,
				lat           : tempCompany.lat,
				lng           : tempCompany.lng,
				googlePlaceId : tempCompany.googlePlaceId,
				gridPoints    : gridPoints,
				pointDistance : pointDistance,
				gridApiType   : 'dseo'
			};

			const reportResponse = await GenerateSEOReportDataGrid(reportData);

			if (reportResponse.success) {
				let tempReport = reportResponse;

				let reportShortName = moment().format('MM-DD-YY-HH-mm');

				let keywordIndex = 0;

				//finally, create or update task for every keyword
				for (const keyword of keywordsArrayForReport) {
					let gridResponseData = tempReport.gridResponse.data.filter(
						(k) => k.keyword.toLowerCase() === keyword.trim().toLowerCase()
					);

					keywordIndex++;

					let taskData = {
						salesCompanyId            : tempCompany.id,
						keyword                   : keyword,
						lat                       : tempCompany.lat,
						lng                       : tempCompany.lng,
						gridPoints                : gridPoints,
						gridPointsDistanceInMiles : pointDistance,
						gridRadius                : areaRadius,
						reportName                : reportShortName,
						orderIndex                : keywordIndex,
						content                   : JSON.stringify({
							googlePlaceId : tempCompany.googlePlaceId,
							gridResponse  : {
								lat              : tempCompany.lat,
								lng              : tempCompany.lng,
								horizontalPoints : gridPoints,
								verticalPoints   : gridPoints,
								data             : gridResponseData
							}
						})
					};

					let isTaskEdit = false;

					if (editCompanyId) {
						//update existing task with new grid report data
						let currentTask = reportTasks.find(
							(x) => x.keyword.toLowerCase() === keyword.trim().toLowerCase()
						);

						if (currentTask && currentTask.id) {
							isTaskEdit = true;
							taskData.id = currentTask.id;
						} else {
							//task keyword not found, create new
							taskData.id = createGuid();
						}
					} else {
						//create new task
						taskData.id = createGuid();
					}

					let taskResponse;

					if (isTaskEdit) {
						taskResponse = await UpdateSalesCompanyTask(taskData.id, taskData);
					} else {
						taskResponse = await CreateSalesCompanyTask(taskData);
					}

					if (taskResponse.success) {
					}
				}

				//finally, we need to set order index for all tasks based on keywords order in the list
				let tasks = await GetSalesCompanyTasks(tempCompany.id);

				if (tasks && tasks.length > 0) {
					tasks.forEach((task) => {
						let keywordIndexFound = keywords
							.split('\n')
							.findIndex((x) => x.toLowerCase() === task.keyword.trim().toLowerCase());

						if (keywordIndexFound > -1) {
							task.orderIndex = keywordIndexFound + 1;
						}
					});

					//update indexes
					await UpdateSalesCompanyTaskIndexes(tasks);
				}

				window.location.href = `${host}/business/${tempCompany.id}/reports/details`;
			} else {
				toast.error(reportResponse.message);
				console.error(reportResponse);
				setIsGenerating(false);
				setIsLoading(false);
			}
		} else {
			setIsGenerating(false);
			setIsLoading(false);
		}
		//}
		//}
	};

	const handleSelectReportTask = async (task) => {
		setIsLoadingMap(true);
		setSelectedTask(task);
		await getReportTaskDetails(task.id, false, true);
	};

	const getReportShareLink = () => {
		var url = new URL(`${host}/public/reports/${selectedTask.id}`);

		copyToClipboard(url.href);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 1000);
	};

	const exportMapAsImage = () => {
		setIsLoadingMap(true);

		let companySlug = slugify(companyName);
		let date = moment().format('MMDDYYYYHHmm');
		let imageName = showGridReport
			? `exported-grid-${companySlug}-${slugify(selectedTask.keyword)}-${date}.png`
			: `exported-map-${companySlug}-${date}.png`;

		let $mapContainer = showGridReport
			? document.querySelector('#report-map-0')
			: document.querySelector('#report-map-preview');

		if ($mapContainer) {
			gridMapContainerRef.current.classList.add('no-transition');

			html2canvas($mapContainer, { useCORS: true })
				.then((canvas) => {
					var imgSrc = canvas.toDataURL('image/png');

					var link = document.createElement('a');
					if (typeof link.download !== 'string') {
						window.open(imgSrc);
					} else {
						link.href = imgSrc;
						link.download = imageName;
						link.click();
						//accountForFirefox(clickLink, link);
					}

					setIsLoadingMap(false);
				})
				.finally(() => {
					gridMapContainerRef.current.classList.remove('no-transition');
					setIsLoadingMap(false);
				});
		}
	};

	const handleCircleMove = (center) => {
		if (center) {
			if (editCompanyId) {
				setGridData({
					...gridData,
					lat : center.lat,
					lng : center.lng
				});
			} else {
				let tempBusiness = { ...selectedBusiness };

				tempBusiness.lat = center.lat;
				tempBusiness.lng = center.lng;

				setSelectedBusiness(tempBusiness);

				StoreSalesBusiness(tempBusiness);
			}
		}
	};

	return (
		<div className="app-container">
			<div className="grid-tool-left-container">
				{showGridReport ? (
					<div className="row p-3">
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<Fragment>
								{companyName && (
									<div className="company-name text-truncate">
										<span>{companyName}</span>
									</div>
								)}

								{dateCreated && (
									<div className="created-date mb-3">
										<Fragment>
											<span>Date Created:</span>
											<span className="ms-2">
												{moment(dateCreated).format('MM/DD/YYYY [@] hh:mm A [EST]')}
											</span>
										</Fragment>
									</div>
								)}

								{companyId && (
									<div className="mb-4">
										<button
											className="btn btn-link p-0 text-decoration-underline"
											onClick={() => navigate(`/grid-report-tool/${companyId}`)}
										>
											Change Keywords or Grid Size
										</button>
									</div>
								)}

								<div>
									{reportTasks &&
										reportTasks.length > 0 &&
										reportTasks.map((task, index) => (
											<div key={index} className="mb-2">
												<button
													className={`btn btn${selectedTask && selectedTask.id === task.id
														? ''
														: '-outline'}-primary btn-lg w-100 `}
													onClick={() => handleSelectReportTask(task)}
													disabled={isGenerating || isLoadingMap || reportId}
												>
													{task.keyword}
												</button>
											</div>
										))}
								</div>
							</Fragment>
						)}
					</div>
				) : (
					<Fragment>
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<div className="row p-3">
								{companyName && (
									<div className="company-name text-truncate">
										<span>{companyName}</span>
									</div>
								)}

								<form className="mt-3">
									<div className="col-12 mb-3">
										<label className="form-label">Service Area Radius</label>
										<div className="row">
											<div className="col-9 pe-0">
												<input
													type="range"
													name="areaRadius"
													className="form-range"
													value={areaRadius || ''}
													onChange={handleChange}
													min={1}
													max={15}
													step={0.5}
													disabled={isGenerating || isLoadingMap}
												/>
											</div>
											<div className="col-3 text-end">
												<span className="area-radius-value">{`${areaRadius || ''} miles`}</span>
											</div>
										</div>
									</div>
									<div className="col-12 mb-3">
										<label className="form-label">Keywords: (one per line, up to 5 terms)</label>
										<textarea
											name="keywords"
											className="form-control"
											value={keywords || ''}
											onChange={handleChange}
											disabled={isGenerating || isLoadingMap}
											rows={5}
										/>
									</div>
									<div className="col-12 text-center">
										<button
											className="btn btn-primary"
											onClick={(e) => generateReport(e)}
											disabled={isGenerating || isLoadingMap}
										>
											{isGenerating ? (
												<Fragment>
													<span className="spinner-border m-0 me-2" />
													<span>Generating Report...</span>
												</Fragment>
											) : (
												<span>Continue</span>
											)}
										</button>
									</div>
								</form>
							</div>
						)}
					</Fragment>
				)}

				{reportTasks &&
				reportTasks.length > 0 &&
				!isLoading && (
					<div className="grid-tool-buttons-container">
						<button
							className="btn btn-link p-0"
							onClick={() => getReportShareLink()}
							disabled={isGenerating || isLoadingMap}
						>
							<span>
								{showShareLinkCopiedMessage ? (
									<span className="bg-success text-white py-1 px-3 rounded">Copied!</span>
								) : (
									'Get Share Link'
								)}
							</span>
						</button>
						<span className="mx-2">|</span>
						<button
							className="btn btn-link p-0"
							onClick={exportMapAsImage}
							disabled={isGenerating || isLoadingMap}
						>
							Export As Image
						</button>

						<div className="mt-3">
							<Link className="btn btn-outline-primary" to="/reports">
								<i className="fa-solid fa-arrow-left-long me-2" />Reports
							</Link>
						</div>
					</div>
				)}
			</div>

			<div className="grid-tool-content-container p-2">
				<div className="map-wrapper h-100 border position-relative" ref={gridMapContainerRef}>
					<Fragment>
						{showGridReport ? (
							<Fragment>
								{(!gridData || isGenerating || isLoadingMap) && (
									<div className="loading-map">
										<Spinner />
									</div>
								)}

								{gridData && (
									<Fragment>
										<GridMapV5
											key="map-0"
											mapIdValue="report-map-0"
											gridData={gridData}
											placeId={gridData.googlePlaceId}
											isFullHeight={true}
											reportSettings={reportSettings}
										/>
									</Fragment>
								)}
							</Fragment>
						) : (
							<Fragment>
								{(!gridData || isGenerating || isLoadingMap) && (
									<div className="loading-map">
										<Spinner />
									</div>
								)}

								{gridData && (
									<GridMapV5
										key="map-preview"
										mapIdValue="report-map-preview"
										gridData={gridData}
										placeId={gridData.googlePlaceId}
										isFullHeight={true}
										showCircle={true}
										circleRadius={areaRadius}
										onCircleMove={handleCircleMove}
										reportSettings={reportSettings}
									/>
								)}
							</Fragment>
						)}
					</Fragment>
				</div>
			</div>
		</div>
	);
};

export default GridReportTool;
