import { Route, Routes } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import CampaignsList from '../../screens/campaigns/campaignsList/CampaignsList';
import CampaignDetails from '../../screens/campaigns/campaignDetails/CampaignDetails';
import PaymentsList from '../../screens/payments/paymentsList/PaymentsList';
import Account from '../account/Account';
import LeftNavigation from '../leftNavigation/LeftNavigation';
import MainAppContentArea from '../mainAppContentArea/MainAppContentArea';
import './MainApp.css';
import OffersList from '../../screens/offers/offersList/OffersList';
import ComingSoon from '../../screens/comingSoon/ComingSoon';
import Support from '../../screens/support/Support';
import { useStateValue } from '../../StateProvider';
import { Fragment } from 'react';
import GridReportsList from '../../screens/salesUsers/gridReportsList/GridReportsList';
import CompanyGridReportsList from '../../screens/salesUsers/companyGridReportsList/CompanyGridReportsList';
import BusinessProfileConnect from '../../screens/salesUsers/businessProfileConnect/BusinessProfileConnect';
import BusinessProfileAddress from '../../screens/salesUsers/businessProfileAddress/BusinessProfileAddress';

const MainApp = () => {
	const [ { user, host }, dispatch ] = useStateValue();

	return (
		<div className="app-container">
			<LeftNavigation />
			<MainAppContentArea>
				<Routes>
					{user.isSalesUser && (
						<Fragment>
							<Route path="/profile-connect" element={<BusinessProfileConnect />} />
							<Route path="/profile-address" element={<BusinessProfileAddress />} />
							<Route path="/reports" element={<GridReportsList />} />
							<Route path="/business/:id/reports/history" element={<CompanyGridReportsList />} />
						</Fragment>
					)}

					<Route path="/campaigns/:campaignId" element={<CampaignDetails />} />
					<Route path="/campaigns" element={<CampaignsList />} />
					<Route path="/account" element={<Account />} />
					<Route path="/payments" element={<PaymentsList />} />
					<Route path="/learning" element={<ComingSoon />} />
					<Route path="/cart" element={<OffersList />} />
					<Route path="/offers" element={<OffersList />} />
					{/* <Route path="/cart" element={<ComingSoon />} /> */}
					<Route path="/support" element={<Support />} />
					<Route path="/*" element={<CampaignsList />} />
				</Routes>
			</MainAppContentArea>
		</div>
	);
};

export default MainApp;
