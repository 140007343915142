import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterContainer from '../../../components/centerContainer/CenterContainer';
import Spinner from '../../../components/spinner/Spinner';
import { GetSalesBusiness, StoreSalesBusiness } from '../../../utils/StorageUtil';
import './../businessProfileConnect/BusinessProfileConnect.css';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import { parseAddressFromGoogleProfile } from '../../../utils/Common';
import GoogleBusinessAutoComplete from '../googleBusinessAutoComplete/GoogleBusinessAutoComplete';

const BusinessProfileAddress = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const navigate = useNavigate();

	const handlePlaceSelected = async (place) => {
		if (place) {
			let tempBusiness = GetSalesBusiness();
			let addressData = parseAddressFromGoogleProfile(place.address_components);

			setSelectedBusiness({
				...tempBusiness,
				...addressData,
				lat : place.geometry.location.lat(),
				lng : place.geometry.location.lng()
			});
		}
	};

	const handleAddressChange = (e) => {
		if (!e.target || !e.target.value || e.target.value.trim() === '') {
			setSelectedBusiness({
				...selectedBusiness,
				streetAddress1 : null,
				city           : null,
				state          : null,
				postalCode     : null,
				country        : null
			});
		}
	};

	const handleAddressSetup = async (e) => {
		e.preventDefault();

		if (!selectedBusiness.streetAddress1 || !selectedBusiness.streetAddress1.trim === '') {
			toast.error('Location or address is required');
			return;
		}

		StoreSalesBusiness(selectedBusiness);

		navigate('/grid-report-tool');
	};

	return (
		<Fragment>
			<div className="main-content-container business-profile-container">
				<div className="main-content-inner-container">
					<div className="business-profile-connect-container">
						{isLoading ? (
							<Spinner />
						) : (
							<Fragment>
								<h2 className="mb-3">Enter Your Business Address</h2>
								<div className="text-start mb-sm-5 mb-4">
									Please enter your office or home office address that you either work from, or start
									from when you leave to go visit your customers.
								</div>

								<div className="mb-3">
									<GoogleBusinessAutoComplete
										onPlaceSelected={handlePlaceSelected}
										onChange={handleAddressChange}
									/>

									{/* <input
									type="text"
									className="form-control"
									placeholder="Enter Address"
									value={address || ''}
									onChange={(e) => setAddress(e.target.value)}
								/> */}
								</div>
								<div className="mb-sm-5 mb-4">
									<button
										className="btn btn-primary"
										onClick={handleAddressSetup}
										disabled={!selectedBusiness || !selectedBusiness.streetAddress1}
									>
										Continue
									</button>
								</div>
								<div className="text-start mb-2 fw-bolder">
									<strong>Why do you need my address?</strong>
								</div>
								<div className="text-start">
									Your business profile is set up as a "Service Area" business and does not have an
									address listed on the profile details. We need your address to make sure we center
									your grid report near what Google believes if...
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default BusinessProfileAddress;
